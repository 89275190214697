import { ParsedUrlQuery } from 'querystring';

const TRACKING_EXCLUDED_PATHNAMES = [
  '/uber-uns/impressum',
  '/uber-uns/datenschutz',
  '/uber-uns/agb',
];

export const isTesting = (query: ParsedUrlQuery): boolean => {
  return query.test === 'true';
};

export const isTrackingAndCcmtExcluded = (
  pathname: string,
  query: ParsedUrlQuery,
): boolean => {
  const isTestingMode = isTesting(query);
  const isExcluded = TRACKING_EXCLUDED_PATHNAMES.includes(pathname);
  return isExcluded || isTestingMode;
};

export const isTrackingAndCcmtIncluded = (
  pathname: string,
  query: ParsedUrlQuery,
): boolean => {
  return !isTrackingAndCcmtExcluded(pathname, query);
};
