export enum ABTestKeys {
  CAT_3165_duration_increase_AB = '2024-11-27_cat-3165_duration_increase_ab_test',
  CAT_3290_best_offer_box_removal_AB = '2025-02-05_cat-3290_best_offer_box_removal',
  CAT_3319_30k_default_amount = '2025-02-20_cat-3319_30k_default_amount',
}

export enum Variation {
  CONTROL = 'control',
  VARIATION_B = 'variation_b',
  VARIATION_C = 'variation_c',
  VARIATION_D = 'variation_d',
  VARIATION_E = 'variation_e',
}

export const ABTestsConfig: { [key in ABTestKeys]: string[] } = {
  [ABTestKeys.CAT_3165_duration_increase_AB]: [
    Variation.CONTROL,
    'variation_a', // var a should not be used
  ],
  [ABTestKeys.CAT_3290_best_offer_box_removal_AB]: [
    Variation.CONTROL, // Best offer box present
    Variation.VARIATION_B, // Best offer box gone
  ],
  [ABTestKeys.CAT_3319_30k_default_amount]: [
    Variation.CONTROL, // Default page amount
    Variation.VARIATION_B, // 30k default amount instead
  ],
};
