import { getGenericTrackingData } from '@finanzcheck/one-segment';

import { InputNames } from 'contexts/LoanSelectionContext';
import { websiteGlobal } from '../platform';

import { TrackingEvents } from './events';

/**
 * Returns a anonymized String:
 * - all normal characters will become A
 * - all accented characters will X
 * - all numeric characters become 9
 * - everything else is left unchanged
 * Special chars are preserved to help in bug hunting
 * @param {String|Number} value
 * @returns {String} anonymized value
 */
export const anonymize = (value: unknown) => {
  // Caution: the order is important for the special chars
  return String(value)
    .replace(/[A-Z]/gi, 'A')
    .replace(/[À-Ž]/g, 'X')
    .replace(/\d/g, '9');
};

const trackToSegment = (
  eventName: string,
  props: { [key: string]: unknown },
  context: Record<string, unknown>,
) => {
  const properties = {
    ...getGenericTrackingData(),
    ...props,
    team: 'catapult',
    tenant: 'finanzcheck',
  };
  if (eventName === TrackingEvents.PAGE_ONVISIT) {
    websiteGlobal.analytics?.page(properties);
  } else {
    websiteGlobal.analytics?.track(
      eventName,
      {
        ...properties,
        path: window.location.pathname,
      },
      context,
    );
  }
};

export const track = (
  eventName: string,
  value?: { [key: string]: unknown } | string | number,
  context?: Record<string, unknown>,
) => {
  // Pollard Tracking has the real event name in the payload
  // otherwise we track alls as "tracking" event
  trackToSegment(
    eventName === TrackingEvents.POLLARD_TRACKING_RECEIVED
      ? (typeof value === 'object' && (value?.event as string)) || eventName
      : eventName,
    typeof value === 'object' ? value : { value },
    context || {},
  );
};

const getTrackingEventByInputName = (inputName: InputNames): TrackingEvents => {
  switch (inputName) {
    case InputNames.Purpose:
      return TrackingEvents.LOANSELECTOR_PURPOSE_ONINTERACT;
    case InputNames.Amount:
      return TrackingEvents.LOANSELECTOR_AMOUNT_ONINTERACT;
    case InputNames.Term:
      return TrackingEvents.LOANSELECTOR_TERM_ONINTERACT;
  }
};

export const trackLoanSelectorChange = (
  inputName: InputNames,
  value: string | number,
) => {
  const trackingEvent: TrackingEvents = getTrackingEventByInputName(inputName);
  if (trackingEvent) {
    track(trackingEvent, { action: 'change', value });
  }
};
