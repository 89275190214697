import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { MdPhone } from 'react-icons/md';
import { finanzcheck } from '@finanzcheck/teal-mui-theme';

import { PhoneLink } from 'components/PhoneLink/PhoneLink';

import { SPACE3, SPEED_MEDIUM } from 'lib/constants/layout';

const { colors } = finanzcheck;
const CallForAdviceWrapper = styled(PhoneLink)`
  display: flex;
  line-height: 1.2;
  text-decoration: none;
`;

const CallForAdviceText = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'none',
  marginRight: SPACE3,
  textAlign: 'right',

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

const CallForAdviceBusinessHours = styled('div')`
  font-size: 0.75rem;
  color: ${colors.text.disabled};
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height ${SPEED_MEDIUM};
  max-height: 24px;
`;

const CallButton = styled('div')`
  align-items: center;
  border: 2px solid ${colors.neutral.grey100};
  border-radius: 50%;
  color: ${colors.text.blue};
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0;
  transition: border-color ${SPEED_MEDIUM};
  width: 40px;
`;

/**
 * Call for advice button
 *
 * @param {Object} props
 * @param {Function} props.onCallForAdviceClick - On click callback
 */
interface CallForAdviceProps {
  onCallForAdviceClick: (event: React.MouseEvent) => void;
  phone: string;
}

export const CallForAdvice: React.FC<CallForAdviceProps> = ({
  onCallForAdviceClick,
  phone,
}) => {
  const [showBusinessHours, setShowBusinessHours] = useState(false);

  return (
    <div
      onMouseOver={() => setShowBusinessHours(true)}
      onMouseOut={() => setShowBusinessHours(false)}
    >
      <CallForAdviceWrapper phone={phone} onClick={onCallForAdviceClick}>
        <CallForAdviceText>
          <b>Kostenlose Beratung</b>
          <br />
          {phone}
          <CallForAdviceBusinessHours
            style={showBusinessHours ? { maxHeight: 24 } : { maxHeight: 0 }}
          >
            Mo - So von 08 - 20 Uhr
          </CallForAdviceBusinessHours>
        </CallForAdviceText>
        <CallButton>
          <MdPhone size="20" />
        </CallButton>
      </CallForAdviceWrapper>
    </div>
  );
};
