import { getAdvertisementId } from 'lib/helpers/advertisementId';
import { useRouter } from 'next/router';
import React, { createContext, useState, useEffect, useContext } from 'react';

const OffersContext = createContext<{ offers: [] }>({
  offers: [],
});

const OffersProvider = ({ children }: { children: React.ReactNode }) => {
  const [data, setData] = useState<[]>([]);

  const { asPath } = useRouter();
  const advertisementId = getAdvertisementId(asPath);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_WAPI_BASE_URL}/v2/offer/${advertisementId}`,
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <OffersContext.Provider value={{ offers: data }}>
      {children}
    </OffersContext.Provider>
  );
};

const useOffers = () => {
  const context = useContext(OffersContext);
  return context;
};

export { OffersProvider, useOffers };
