import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Collapse, Grid, ListItemText, ListItem, List } from '@mui/material';

import { finanzcheck } from '@finanzcheck/teal-mui-theme';
import {
  SPACE10,
  SPACE2,
  SPEED_MEDIUM,
  TIMING_FUNCTION,
} from '../../../lib/constants/layout';
import { Button } from '../../BaseElements/Button/Button';
import { HeaderTemplate } from '../HeaderTemplate/HeaderTemplate';
import { TrackingEvents } from '../../../lib/tracking/events';
import { track } from '../../../lib/tracking/helper';

import menuData from './menuData.json';
const { colors } = finanzcheck;

const clientAreaUrl = process.env.WEBSITE_CLIENTAREA_URL;

const Overlay = styled('div', {
  shouldForwardProp: (props) => props !== 'show',
})<{ show: boolean }>(({ show }) => ({
  zIndex: 9999,
  backgroundColor: colors.background.default,
  bottom: 0,
  left: 0,
  opacity: show ? 1 : 0,
  overflow: 'auto',
  position: 'fixed',
  right: 0,
  top: 0,
  transition: `opacity ${SPEED_MEDIUM} ${TIMING_FUNCTION}`,
}));

const StyledHeading = styled('div')`
  border-bottom: 1px solid ${colors.neutral.grey100};
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
  :last-child {
    border-bottom: none;
  }
`;

const StyledNavigationItems = styled('div')`
  color: rgb(98, 104, 110);
  font-weight: 400;
  padding-left: ${SPACE2};
`;

const NavigationButton = styled(Button)`
  margin: ${SPACE10} 0 0 0;
`;

/**
 * Overlay containing the whole navigation for mobile
 *
 * @param {string}    props.title - Navigation heading
 * @param {string}    props.href  - Link for navigation items
 * @param {Node}    props.subMenu - Items under navigation heading
 */
interface ListNavigationItemsProps {
  title: string;
  href: string;
  subMenu?: { label: string; url: string }[] | null;
}

const ListNavigationItems: React.FC<ListNavigationItemsProps> = ({
  title,
  href,
  subMenu,
}) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const listItemProps = subMenu
    ? { onClick: () => setSubMenuOpen(!subMenuOpen) }
    : { component: 'a', href };
  return (
    <>
      <ListItem button {...listItemProps}>
        <ListItemText primary={title} />
      </ListItem>
      {subMenu && (
        <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
          <List>
            <StyledNavigationItems>
              <ListItem component="a" href={href} button>
                <ListItemText primary={title} />
              </ListItem>
              {subMenu.map((subMenuItem) => (
                <ListNavigationItems
                  key={subMenuItem.label}
                  href={subMenuItem.url}
                  title={subMenuItem.label}
                />
              ))}
            </StyledNavigationItems>
          </List>
        </Collapse>
      )}
    </>
  );
};

/**
 * Overlay containing the whole navigation for mobile
 *
 * @param {Object}  props
 * @param {boolean} props.show  - Indicates if the overlay should be shown or not
 * @param {Node}    props.Close - Close button component to be passed to `HeaderTemplate` which will be used as header for the overlay
 */

interface MobileOverlayProps {
  show: boolean;
  Close: React.ReactNode;
}
export const MobileOverlay: React.FC<MobileOverlayProps> = ({
  Close,
  show,
}) => {
  // Intentional use of useMediaQuery
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Overlay show={show} data-testid={'mobile-overlay'}>
      <HeaderTemplate TopLeftButton={Close} />
      <Grid container data-testid={'burgermenu_mobile_overlay'}>
        <Grid item xs={12}>
          <List>
            {menuData.map((item) => (
              <StyledHeading key={item.label}>
                <ListNavigationItems
                  href={item.url}
                  title={item.label}
                  subMenu={item.subMenu}
                />
              </StyledHeading>
            ))}
          </List>
          <NavigationButton
            href={clientAreaUrl}
            onClick={() => {
              track(TrackingEvents.LOGIN_LINK_ONINTERACT);
            }}
            color="secondary"
            fullWidth={onlySmallScreen}
          >
            Login Kundenbereich
          </NavigationButton>
        </Grid>
      </Grid>
    </Overlay>
  );
};
