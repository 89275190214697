import { useRouter } from 'next/router';
import { useContext, createContext, useState, useEffect } from 'react';

import { DEFAULT_SELECTION_VALUES } from 'lib/offers/filters';
import { getLoanSelectionFromUrlParams } from 'lib/urlParams/queryParamsHelper';

import { usePageConfig } from './PageConfigContext';
import { useDecision } from 'lib/optimizely';
import { ABTestKeys, Variation } from 'lib/optimizely/flagConfigs';
import { PURPOSES } from 'lib/constants/offerFilter';

export enum InputNames {
  Purpose = 'purpose',
  Amount = 'amount',
  Term = 'term',
}

export type LoanSelectionProps = {
  [InputNames.Amount]: number;
  [InputNames.Purpose]: string;
  [InputNames.Term]: number;
};

type LoanSelectionContextProps = {
  loanSelection: LoanSelectionProps;
  setLoanSelection: (props: LoanSelectionProps) => void;
};

/**
 * Create the context itself that is used within the
 * consumer and provider
 */
const LoanSelectionContext = createContext<LoanSelectionContextProps>({
  loanSelection: DEFAULT_SELECTION_VALUES,
  setLoanSelection: () => {},
});

/**
 * The provider acts a wrapper to provide the context
 * to its children
 */
const LoanSelectionProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const { pageConfig } = usePageConfig();
  const [loanSelection, setLoanSelection] = useState<LoanSelectionProps>(
    DEFAULT_SELECTION_VALUES,
  );

  const [{ variationKey }] = useDecision(
    ABTestKeys.CAT_3165_duration_increase_AB,
  );
  const [{ variationKey: amountVariationKey }] = useDecision(
    ABTestKeys.CAT_3319_30k_default_amount,
  );

  useEffect(() => {
    const initialLoanSelection = getInitialLoanSelection();
    setLoanSelection(initialLoanSelection);
    // These are the true dependencies that could change after
    // initial loading of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageConfig.amount, pageConfig.term, pageConfig.purpose]);

  const getInitialLoanSelection = () => {
    // The intial default is set by ascending importance:
    // 1. Hardcoded Defaults
    // 2. PageConfig Default Overrides (if any)
    // 3. URL Overrides (if any)
    // The loanSelection state will then override these defaults
    // after the initial loading is done
    const isMortgage = PURPOSES.real_estate_financing === pageConfig.purpose;
    const defaultLoanSelection = DEFAULT_SELECTION_VALUES;
    const pageConfigOverrides = {
      amount:
        amountVariationKey === Variation.VARIATION_B && !isMortgage
          ? 30000
          : pageConfig.amount,
      term: variationKey === Variation.VARIATION_B ? 96 : pageConfig.term,
      purpose: pageConfig.purpose,
    };

    // We don't have windows here, so we need to get the url from the router
    // We cannot use the router.query here, because it is not updated
    const url = `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}${router.asPath}`;
    const urlOverrides = getLoanSelectionFromUrlParams(url);

    return {
      ...defaultLoanSelection,
      ...pageConfigOverrides,
      ...(urlOverrides?.amount && { amount: urlOverrides.amount }),
      ...(urlOverrides?.term && { term: urlOverrides.term }),
      ...(urlOverrides?.purpose && { purpose: urlOverrides.purpose }),
    } as LoanSelectionProps;
  };

  const value: LoanSelectionContextProps = {
    loanSelection,
    setLoanSelection,
  };

  return (
    <LoanSelectionContext.Provider value={value}>
      {children}
    </LoanSelectionContext.Provider>
  );
};

const useLoanSelection = () => {
  const context = useContext(LoanSelectionContext);
  return context;
};

export { LoanSelectionProvider, useLoanSelection };
